<template>
  <v-app style="background-color: white; scroll-behavior: smooth !important;" class="desktopLayoutClass">
    <!-- Dialog Start -->
    <div justify="center">
      <v-dialog v-model="cannotProceedFlag" persistent max-width="290">
        <v-card>
            <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Cant_Proceed") }}
            </v-card-title>
            <v-card-actions>
                <v-btn color="primary" depressed @click="closeCantProceedPopup" style="margin: auto;background-color: #1467BF;">
                    {{ $t("Warning_Message.Home_Page") }}
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div justify="center">
      <v-dialog v-model="avialPromoSccessModal" persistent max-width="290">
        <v-card>
            <div>
                <img
                class="availPromoSuccessImg"
                src="https://s3iconimages.mymedicine.com.mm/success_tick.svg">
            </div>
          <v-card-title style="word-break:normal; " class="availPromoCodeText">
              <span style="font-weight: 700; font-size: 16px; padding-right:2px">{{availedPromoCode}} </span> {{ $t("Customer.Myoffers.Applied") }}
          </v-card-title>
          <v-card-text>
              <div class="availPromoDialogText">
                  <p>{{ $t("Customer.Myoffers.You_Saved") }} <span style="color:#1467BF">{{discountedAmount}}{{ $t("Customer.Myoffers.X_MMK") }}</span> </p>
              </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            depressed
            width="250"
            color="primary"
            @click="closeAvailPromoDialog">
              {{$t("Warning_Message.Ok")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div justify="center">
      <v-dialog v-model="avialCouponSccessModal" persistent max-width="290">
        <v-card>
            <div>
                <img
                class="availPromoSuccessImg"
                src="https://s3iconimages.mymedicine.com.mm/success_tick.svg">
            </div>
          <v-card-title style="word-break:normal; " class="availPromoCodeText">
              <span style="font-weight: 700; font-size: 16px; padding-right:2px">{{couponCode}} </span> {{ $t("Customer.Myoffers.Applied") }}
          </v-card-title>
          <v-card-text>
              <div class="availPromoDialogText">
                  <p>{{ $t("Customer.Myoffers.You_Saved") }} <span style="color:#1467BF">{{discountedAmount}}{{ $t("Customer.Myoffers.X_MMK") }}</span> </p>
              </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            depressed
            width="250"
            color="primary"
            @click="closeAvailCouponDialog">
                {{$t("Warning_Message.Ok")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div justify="center">
      <v-dialog v-model="avialPromoErrorModal" persistent max-width="290">
        <v-card>
            <div>
                <img
                class="availPromoSuccessImg"
                src="https://s3iconimages.mymedicine.com.mm/WarningDialog.svg">
            </div>
          <!-- <v-card-title style="word-break:normal; " class="availPromoCodeText">
              <span style="font-weight: 700; font-size: 16px; padding-right:2px">{{availedPromoCode}} </span> applied
          </v-card-title> -->
          <v-card-text v-if="promoErrorrStatusCode == '412'">
                  <p style="color:black; font-size:15px" ><span style="color:#1467BF"></span> Minimum spend amount to use this promo code is {{minimumSpentAmount}}MMK. Please use {{minimumSpentAmount}} MMK or more to get the promotion.  </p>
          </v-card-text>
          <v-card-text v-else>
                  <p style="color:black; font-size:15px" >{{ $t("Customer.Myoffers.Promo") }} <span style="color:#1467BF">{{availedPromoCode}}</span>{{ $t("Customer.Myoffers.Curently_Not_Valid") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            depressed
            width="250"
            color="primary"
            @click="avialPromoErrorModal=false">
                {{$t("Warning_Message.Ok")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Dialog End -->

    <div
      v-show="show_header"
      class="header"
      style="display: flex; flex-direction: row; padding-top: 10px; padding-left: 5px;"
    >
      <div class="bookAppointmentMainDiv" style="display: flex; flex-direction: row; width: 100%">
        <div
          style="width: 10%; padding-top: 6px"
          align="left"
          v-on:click="redirectToHomePage()"
          >
            <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
        </div>
        <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.Myoffers.My_Offers") }}</span>
        </div>
      </div>
    </div>
    <div  style="padding: 10px 20px !important; height: 98vh;">
        <div>
          <p style=" margin-bottom:2px; text-align:left !important; line-height: 36px;">{{ $t("Customer.Myoffers.Enter_Coupon_Code") }}</p>
          <v-text-field
            :placeholder="translation_coupon_code"
            v-model="couponCode"
            style="border-radius: 8px; margin-bottom:15px"
            outlined
            hide-details="true"
            >
              <!--  -->
              <template v-slot:append >
                <v-btn
                    depressed
                    rounded
                    :disabled="isApplyBtnClicked"
                    color="primary"
                    style="border-radius:8px; margin-bottom:10px"
                    @click="applyCoupon(couponCode)"
                    >
                    {{ $t("Customer.Myoffers.Apply") }}
                  </v-btn>
              </template>
          </v-text-field>
          <div style="margin:2px">
            <!-- <p v-if="couponStatus == true" style="color: #88CE65; font-size:14px; letter-spacing: 0.090em" >You have Saved {{discountAmount}} MMK</p> -->
            <p v-if="couponErrorStatusCode == '412'" style="color: #EB5757; font-size:14px; letter-spacing: 0.090em" >{{ $t("Customer.Myoffers.Spent_To_Avail_Coupon") }} {{minAmountToBeSpent}} {{ $t("Customer.Myoffers.X_MMK") }}  </p>
            <p v-else-if="couponErrorStatusCode == '409'" style="color: #EB5757; font-size:14px; letter-spacing: 0.090em" >{{ $t("Customer.Myoffers.Coupon_Not_Valid_Particular_Doctor/Specialization") }}</p>
            <p v-else-if="couponErrorStatusCode == '410'" style="color: #EB5757; font-size:14px; letter-spacing: 0.090em" >{{ $t("Customer.Myoffers.Coupon_Currently_Not_Valid") }}</p>
            <p v-else-if="couponErrorStatusCode == '400'" style="color: #EB5757; font-size:14px; letter-spacing: 0.090em" >{{ $t("Customer.Myoffers.Coupon_Invalid") }}</p>
            <p v-else-if="couponErrorStatusCode == '507'" style="color: #EB5757; font-size:14px; letter-spacing: 0.090em" >{{ $t("Customer.Myoffers.Invalid_User") }}</p>
          </div>
        </div>
        <v-divider />
        <div v-if="!isLoadingPromo && (listOfCorporate.length > 0 || listOfPromo.length > 0)">
          <div v-if="listOfCorporate.length > 0">
            <v-tabs
              v-model="table_items"
              fixed-tabs
            >
            <v-tabs-slider></v-tabs-slider>
              <v-tab href="#Packages">
                {{ $t("Customer.Myoffers.Packages") }}
              </v-tab>
              <v-tab href="#Promos">
                {{ $t("Customer.Myoffers.Promos") }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="table_items">
              <v-tab-item style="padding-top: 15px; overflow: scroll; height: 70vh;" key="Packages" value="Packages">
                <div v-for="Package in listOfCorporate" :key="Package.corporatePackage._id" style="padding: 10px 5px;">
                  <v-card elevation = 0 class="couponCard">
                      <div class="py-4 d-flex justify-start align-center couponCardTitle">
                          <div class="d-flex justify-start align-center" style="width: 100%;">
                            <span class="ma-0 pa-2 couponText" style="max-width: 60%;">{{Package.corporatePackage.corporate_program_name}}</span>
                          </div>
                      </div>

                      <div class="py-2">
                        <div class="ma-0 couponDescription">
                          <span v-if="!readMore">{{Package.corporatePackage.description.slice(0,100)}}</span>
                          <span v-if="Package.corporatePackage.description.length > 100 && !readMore" @click="readMore = true" class="hoverHand">
                            <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_More") }}</b>
                          </span>
                          <div v-if="readMore">
                            <span>{{ Package.corporatePackage.description }}</span>
                            <span v-if="Package.corporatePackage.description.length > 100" @click="readMore = false" class="hoverHand">
                              <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_Less") }}</b>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div v-if="Package.redemption_limit == null" align="left">
                          <!-- <p class="couponDescription">{{ $t("Customer.Myoffers.Remaining_Claims") }}: - / -</p> -->
                          <div v-if="Package.frequency_end_date && Package.frequency_end_date != ''" class="warningColorClass pa-2 rounded-lg">
                            <p class="couponDescription">
                              <span v-if="Package.frequency.booking_count - Package.remaining_frequency_count > 0">{{ $t("Customer.Myoffers.Frequency_Info_1") }}
                                <span>{{ Package.frequency.booking_count - Package.remaining_frequency_count }}</span>
                                <span>{{ $t("Customer.Myoffers.Frequency_Info_2") }}</span>
                              </span>
                              <span>{{ $t("Customer.Myoffers.Frequency_Info_3") }} {{ Package.remaining_frequency_count }} {{ $t("Customer.Myoffers.Frequency_Info_4") }} {{ Package.frequency_end_date }}.</span>
                            </p>
                          </div>
                          <div v-else class="warningColorClass pa-2 rounded-lg">
                            <p class="couponDescription ma-0" align="left"> <span class="font-weight-bold"> {{ $t("Customer.Myoffers.Condition") }}: </span> <span> {{ Package.frequency.booking_count }} {{ $t("Customer.Myoffers.Frequency_Bookings") }} / {{ Package.frequency.number_of_days }} {{ $t("Customer.Myoffers.Frequency_Days") }} </span></p>
                          </div>
                      </div>
                      <div v-else align="left">
                        <p class="couponDescription">{{ $t("Customer.Myoffers.Remaining_Claims") }}: {{Package.redemption_limit}} / {{Package.total_count}}</p>
                        <div v-if="Package.frequency_end_date && Package.frequency_end_date != ''" class="warningColorClass pa-2 rounded-lg">
                          <p class="couponDescription">
                            <span v-if="Package.redemption_limit > 0 && Package.frequency.booking_count - Package.remaining_frequency_count > 0">{{ $t("Customer.Myoffers.Frequency_Info_1") }}
                              <span>{{ Package.frequency.booking_count - Package.remaining_frequency_count }}</span>
                              <span>{{ $t("Customer.Myoffers.Frequency_Info_2") }}</span>
                            </span>
                            <span v-if="Package.redemption_limit > 0">{{ $t("Customer.Myoffers.Frequency_Info_3") }} {{ Package.remaining_frequency_count }} {{ $t("Customer.Myoffers.Frequency_Info_4") }} {{ Package.frequency_end_date }}.</span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <v-btn
                          depressed
                          color="primary"
                          width="100%"
                          style="margin-top: 5px;"
                          :disabled="isPackagreApplyClicked  || Package.dependentStatus === 'INACTIVE' || Package.maxRedemption"
                          @click="applyPackage(Package)"
                          class="mt-4"
                        >
                          {{ $t("Customer.Myoffers.Apply") }}
                        </v-btn>
                      </div>
                  </v-card>
                </div>
              </v-tab-item>
              <v-tab-item style="padding-top: 15px; overflow: scroll; height: 70vh;" key="Promos" value="Promos">
                <div v-for="promo in listOfPromo" :key="promo.offer_code">
                    <v-card class="couponCard">
                      <div style="display:flex; justify-content:space-between; padding-top: 5px;">
                        <div style="display:flex;">
                          <div class="offer_img" v-if="promo.offer_img">
                            <img :src="promo.offer_img" style="width:100%; height:100%;"> 
                          </div>
                          <div style="display:flex; flex-direction:column; text-align:left;">
                            <p style="margin-bottom: 0px; font-size: 12px; font-weight:500; line-height:16px;" v-if="promo.discount_type === 'PERCENTAGE_OFF'">Flat {{promo.discount}}% off upto {{promo.maximum_discount}} MMK</p>
                            <p style="margin-bottom: 0px; font-size: 12px; font-weight:500; line-height:16px;" v-else>Flat {{promo.discount}} MMK off </p>
                            <div>
                              <p style="margin-bottom: 0px; font-size: 10px; font-weight:400; line-height:16px; color:#828282;">
                                <span v-if="!readMorePromo">{{promo.description.slice(0,100)}}</span>
                                <span v-if="promo.description.length>100 && !readMorePromo" @click="readMorePromo = true" class="hoverHand">
                                  <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_More") }}</b>
                                </span>
                                <div v-if="readMorePromo" style="margin-bottom: 0px; font-size: 10px; font-weight:400; line-height:16px; color:#828282;">
                                  <span>{{ promo.description }}</span>
                                  <span @click="readMorePromo = false" class="hoverHand" v-if="promo.description.length>100">
                                    <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_Less") }}</b>
                                  </span>
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="apply_button" style="cursor:pointer;"  :disabled = 'isApplyBtnClicked' @click="applyPromo(promo.offer_code)"><span class="apply_text">{{ $t("Customer.Myoffers.Apply") }}</span></div>
                      </div>                       
                    </v-card>
                </div>
                <div v-if="listOfPromo.length <= 0" style="padding-top: 50%;">
                  <div>
                    <img src="https://s3iconimages.mymedicine.com.mm/no_promo.svg">
                  </div>
                  <div>
                    <p style="font-weight: 500; font-size: 16px; line-height: 16px; padding-top: 15px;">{{$t("Customer.Myoffers.No_Promo_Heading")}}</p>
                    <p style="font-weight: 300; font-size: 15px; line-height: 16px;">{{$t("Customer.Myoffers.No_Promo_Body")}}</p>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div v-else>
            <div v-if="listOfPromo.length > 0" style="padding-top: 20px;">
                <h4 class="availableCoupontitle">{{ $t("Customer.Myoffers.Available_Promo") }}</h4>
            </div>
            <div v-for="promo in listOfPromo" :key="promo.offer_code">
                    <v-card class="couponCard">
                      <div style="display:flex; justify-content:space-between; padding-top: 5px;">
                        <div style="display:flex;">
                          <div class="offer_img" v-if="promo.offer_img">
                            <img :src="promo.offer_img" style="width:100%; height:100%;"> 
                          </div>
                          <div style="display:flex; flex-direction:column; text-align:left;">
                            <p style="margin-bottom: 0px; font-size: 12px; font-weight:500; line-height:16px;" v-if="promo.discount_type === 'PERCENTAGE_OFF'">Flat {{promo.discount}}% off upto {{promo.maximum_discount}} MMK</p>
                            <p style="margin-bottom: 0px; font-size: 12px; font-weight:500; line-height:16px;" v-else>Flat {{promo.discount}} MMK off </p>
                            <div>
                              <p style="margin-bottom: 0px; font-size: 10px; font-weight:400; line-height:16px; color:#828282;">
                                <span v-if="!readMorePromo">{{promo.description.slice(0,100)}}</span>
                                <span v-if="promo.description.length>100 && !readMorePromo" @click="readMorePromo = true" class="hoverHand">
                                  <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_More") }}</b>
                                </span>
                                <div v-if="readMorePromo" style="margin-bottom: 0px; font-size: 10px; font-weight:400; line-height:16px; color:#828282;">
                                  <span>{{ promo.description }}</span>
                                  <span @click="readMorePromo = false" class="hoverHand">
                                    <b style="color: #1467BF;">{{ $t ("Customer.MySubscription.Read_Less") }}</b>
                                  </span>
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="apply_button" style="cursor:pointer;" :disabled = 'isApplyBtnClicked' @click="applyPromo(promo.offer_code)"><span class="apply_text">{{ $t("Customer.Myoffers.Apply") }}</span></div>
                      </div>                       
                    </v-card>
                </div>
          </div>
        </div>
        <div v-else-if = "isLoadingPromo">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else style="padding: 50% 0;">
          <div>
            <img src="https://s3iconimages.mymedicine.com.mm/no_promo.svg">
          </div>
          <div>
            <p style="font-weight: 500; font-size: 16px; line-height: 16px; padding-top: 15px;">{{$t("Customer.Myoffers.No_Offer_Heading")}}</p>
            <p style="font-weight: 300; font-size: 15px; line-height: 16px;">{{$t("Customer.Myoffers.No_Offer_Body")}}</p>
          </div>
        </div>
    </div>
  </v-app>
</template>
<script>
import axios from "axios";
import { axios_auth_instance_customer } from '../../../utils/axios_utils';
export default{
    data(){
        return{
            couponCode:"",
            couponStatus:false,
            couponErrorStatusCode:"",
            currentCustomer:'',
            bookingDetails:"",
            customerId:'',
            doctorId:'',
            specializationId:'',
            amountSpent:'',
            listOfPromo:[],
            avialPromoSccessModal:false,
            availedPromoCode:"",
            discountedAmount:"",
            promoErrorrStatusCode:"",
            avialPromoErrorModal:false,
            avialCouponSccessModal:false,
            show_header: true,
            isLoadingPromo:true,
            minimumSpentAmount:'',
            table_items: null,
            items: ['Corporate Programs', 'Available Promos'],
            listOfCorporate: [],
            applicable_benefit: "",
            translation_coupon_code: "Enter Coupon Code",
            isApplyBtnClicked:false,
            isPackagreApplyClicked: false,
            cannotProceedFlag: false,
            corporatePackageName: '',
            frequency: {},
            packageDetails: {},
            readMore: false,
            readMorePromo: false
        }
    },
    // components{},
    mounted(){
      if(window.xm)
        this.currentCustomer = localStorage.getItem("customerToken");
      else
        this.currentCustomer = this.$cookies.get("customerToken");
      if(!this.currentCustomer){
        this.$router.push({
          name : "Login"
        })
      }
      else{
        //   var params = this.$route.params.booking_details_from_params;
        var userAgent = navigator.userAgent;
        if(userAgent.includes("kbzpay")){
          this.show_header = false;
        }
        if(this.$store.state.locale !== "")
        {
            this.$i18n.locale =  this.$store.state.locale;
        }
        else
        {
            this.$i18n.locale = "mm";
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
        if(this.$i18n.locale == 'en')
        {
          this.translation_coupon_code = "Enter Coupon Code";
          document.title = "My Offers";
        }
        else
        {
          this.translation_coupon_code = "ကူပွန်ဖြည့်သွင်းပါ";
          document.title = "ကမ်းလှမ်းချက်များ";
        }
          let localData =localStorage.getItem("BookingDetails") //if no data back to book appointment
          var appointment_data = JSON.parse(localData);
          // console.log("appointment Data", appointment_data.bookedById)
          if(!appointment_data) {
            // Popup & redirect to Home Screen.
            this.cannotProceedFlag = true;
          }
          this.bookingDetails = appointment_data;
          this.customerId = this.bookingDetails.bookedById;
          this.doctorId = this.bookingDetails.doctorId;
          this.specializationId = this.bookingDetails.specialization_id;
          this.amountSpent = this.bookingDetails.amount;
          var vaidPromoBody ={
          "token" : this.currentCustomer,
          "typeOfUser" : "CUSTOMER",
          "doctorId": this.doctorId,
          "specializationId": this.specializationId,
          "amountToCheck": this.amountSpent,
          "bookForId": this.bookingDetails.bookedForId
          }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidPromotions", vaidPromoBody)
          .then((getValidPromotionsResponse) => {
              // this.listOfPromo = getValidPromotionsResponse.data.promotion_data;
              this.promoLength = getValidPromotionsResponse.data.promotion_data.promoLength;
              this.listOfPromo = getValidPromotionsResponse.data.promotion_data.promoLength > 0 ? getValidPromotionsResponse.data.promotion_data.promoRecords : [];
              this.listOfCorporate = getValidPromotionsResponse.data.corporate_data;
              this.listOfCorporate.forEach((corporate_package_record) => {
                corporate_package_record.corporatePackage.benefits.forEach((benefit_record)=>{
                    let foundvar = benefit_record.applicable_list.find(ele => ele == this.bookingDetails.specialization_id || ele == this.bookingDetails.doctorId)
                    if(foundvar)
                    {
                      corporate_package_record.total_count = benefit_record.promotion_count;
                    }
                })
              })
              this.isLoadingPromo = false;
          })
          .catch((getValidPromotionsError)=>{
              console.log("Error Response",getValidPromotionsError)
              this.isLoadingPromo = false
          })
      }
    },
    methods: {
      closeCantProceedPopup() {
        this.cannotProceedFlag = false;
        this.$router.push({
          name: 'CustomerHomeScreen'
        });
      },
      redirectToHomePage(){
        this.$router.go(-1);
      },
      applyPackage(packageObject) {
        this.isPackagreApplyClicked = true;
        this.availedPromoCode="";

        var availPackageBody = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER",
            "corporatePackageId":packageObject.corporatePackage._id,
            // "corporateEmployeeId": packageObject.corporateEmployeeId,
            // "customerId": this.customerId,
            "doctorId":this.doctorId,
            "specializationId": this.specializationId,
            // "amount":this.amountSpent
        };
        axios_auth_instance_customer.post("/availBenefit", availPackageBody)
        .then((availPackageSuccess) => {
            this.bookingDetails.discountedAmount = availPackageSuccess.data.discount;
            this.bookingDetails.amountToBePaid= availPackageSuccess.data.amount_to_be_paid;
            this.bookingDetails.offerReferenceCode = packageObject.corporatePackage._id;
            this.bookingDetails.offerType = "CORPORATE"
            this.bookingDetails.corporate_full_info = packageObject;
            localStorage.setItem("BookingDetails",JSON.stringify(this.bookingDetails))
            this.isPackagreApplyClicked = true;
            this.$router.push({
              name: "verifyAppointment"
            });
        })
        .catch((availPromotionError)=>{
            console.log(availPromotionError);
              this.promoErrorrStatusCode = availPromotionError.response.status
              this.isPackagreApplyClicked = true
              if(this.promoErrorrStatusCode == '412'){
                this.minimumSpentAmount = availPromotionError.response.data.min_amount_spend
              }
              handleError(availPromotionError, this.$router, 'availPromotionError', 'Login', 'customerToken');
        });

      },
      applyPromo(displayCode){
          this.availedPromoCode="";
          this.isApplyBtnClicked = true;
          var availPromotionBody ={
              "token" : this.currentCustomer,
              "typeOfUser" : "CUSTOMER",
              "docId":this.doctorId,
              "specId": this.specializationId,
              "offerCode": displayCode
          }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/applyPromo", availPromotionBody)
          .then((applyPromoSuccessResponse)=>{
            if(applyPromoSuccessResponse && applyPromoSuccessResponse.data && applyPromoSuccessResponse.data.data && applyPromoSuccessResponse.data.data.APPLICABLE) {
              this.availedPromoCode = displayCode
              // this.avialPromoSccessModal = true
              this.discountedAmount = applyPromoSuccessResponse.data.data.discountedAmount;
              this.bookingDetails.discountAmount = this.discountedAmount;
              this.bookingDetails.amountToBePaid= applyPromoSuccessResponse.data.data.amountToBePaid
              this.bookingDetails.offerReferenceCode = displayCode
              this.bookingDetails.offerType = "PROMOTION"
              localStorage.setItem("BookingDetails", JSON.stringify(this.bookingDetails));
              this.$router.push({
                name: "verifyAppointment"
              });
            } else {
                this.availedPromoCode = displayCode+" ";
                this.avialPromoErrorModal = true;
                this.isApplyBtnClicked=false;
            }
          })
          .catch((availPromotionError)=>{
                this.availedPromoCode = displayCode+" ";
                this.avialPromoErrorModal = true;
                this.promoErrorrStatusCode = availPromotionError.response.status
                if(this.promoErrorrStatusCode == '412'){
                  this.minimumSpentAmount = availPromotionError.response.data.min_amount_spend
                }
                this.isApplyBtnClicked=false;
          })
      },
    applyCoupon(displayCode){
        this.isApplyBtnClicked = true
        this.couponErrorStatusCode = "";
        this.couponStatus = false;
        var applyCouponBody = {
            token: this.currentCustomer,
            typeOfUser: "CUSTOMER",
            couponCode: this.couponCode,
            // customerId:this.customerId,
            // amountSpent:this.amountSpent,
            specId: this.specializationId,
            docId: this.doctorId
        };
        axios
        .post(process.env.VUE_APP_BACKEND_URL + "/applyCoupon",applyCouponBody)
        .then((applyCouponRes) => {
            this.isApplyBtnClicked = false;
            if(applyCouponRes && applyCouponRes.data && applyCouponRes.data.data && applyCouponRes.data.data.APPLICABLE){
                this.couponStatus = true
                this.discountedAmount = applyCouponRes.data.data.discountedAmount
                this.amountAfterDiscount = applyCouponRes.data.data.amountToBePaid
                this.bookingDetails.offerReferenceCode = this.couponCode
                this.bookingDetails.offerType = "COUPON"
                this.bookingDetails.amountToBePaid = this.amountAfterDiscount;
                this.bookingDetails.discountAmount = this.discountedAmount;
                localStorage.setItem("BookingDetails", JSON.stringify(this.bookingDetails))
                // this.avialCouponSccessModal = true
                this.closeAvailCouponDialog()
            }
            else{
                this.couponStatus = false;
                this.availedPromoCode = displayCode+" ";
                this.avialPromoErrorModal = true;
            }
        }).catch((availCouponError) => {
            this.availedPromoCode = displayCode+" ";
            this.couponStatus = false;
            this.avialPromoErrorModal = true;
            this.couponErrorStatusCode =availCouponError.response.status;
            console.log(this.couponErrorStatusCode);
            this.isApplyBtnClicked = false;
            if(availCouponError.response.status == 412){
                this.minAmountToBeSpent =availCouponError.response.data.min_amount_spend
                // this.couponErrorStatusCode = 412
            }
        });
    },
      cancelCoupon(){
        this.couponStatus = false;
        this.couponCode= "";
        this.couponErrorStatusCode = "";
      },
      closeAvailPromoDialog(){
          this.avialPromoSccessModal = false
            this.$router.push({
                name:"verifyAppointment"
      });
      },
      closeAvailCouponDialog(){
        this.avialPromoSccessModal = false
        this.$router.push({
          name:"verifyAppointment"
        });
      }
    }
}
</script>
<style scoped>
.hoverHand {
    cursor: pointer;
}
.apply_text {
    width: 33px;
    height: 16px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.apply_button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;

  width: 70px;
  height: 45px;

  white-space: nowrap;
  text-align: center;
  /* Primary */

  background: #48ACEF;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.offer_img {
  
width: 46px;
height: 46px;
border-radius: 4px;
margin-right: 10px;
}
.warningColorClass {
  color: #F89035;
  background-color: #F890351F;
}

.availableCoupontitle{
    text-align: left;
    color: #000000;
    font-weight: 700;
}
.couponCard {
  /* box-shadow: unset; */
  border: 1px solid #E0E0E0;
  padding: 10px 15px 15px 15px;
  border-radius: 8px;
  margin: 10px 4px;
}
.couponCardTitle{
    background-image: url('https://s3iconimages.mymedicine.com.mm/corporatePackageDecor.svg');
    /* background-size: 200px 65px; */
    background-size: 60% 100%;
}
.couponText{
    color:black;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
    /* margin-top:-2px;
    margin-left:8px; */
    text-transform:uppercase;
    text-align: left;
    word-wrap: break-word;
}
#flag::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 17.5px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 17.5 px solid #E4F5FF !important;
  border-left: 10px solid transparent;
  border-right: 15px solid white;
  border-bottom: 17.5px solid #E4F5FF;
}
#flag {
      width: 50%;
      height: 22px;
      box-sizing: content-box;
      padding-top: 15px;
      position: relative;
      background: #E4F5FF;
      color: white;
      /* font-size: 11px; */
      /* letter-spacing: 0.2em; */
      /* border: 1px solid #73C3F9; */
      text-align: center;
      text-transform: uppercase;
    }
    .couponDescription{
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 8px;
        text-align: left;
    }
    .availPromoSuccessImg{
        padding: 10px
    }
    .availPromoCodeText{
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        padding-top:0px !important
    }
    .availPromoDialogText{
        font-weight: 600;
        font-size: 24px !important;
        line-height: 24px;
        color: #000000;
    }
</style>